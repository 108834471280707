import bowserLaughSound from '../sounds/bowser-laugh.mp3';
import bowserSong from '../sounds/bowser-song.mp3';
import static from '../images/static.png';
import mutantRoaring from '../models/MutantRoaring.fbx';
import mutantIdle from '../models/MutantIdle.fbx';
import mutantBreathingIdle from '../models/MutantBreathingIdle.fbx';
import mutantIdleTwo from '../models/MutantIdleTwo.fbx';
import mutantFlexingMuscles from '../models/MutantFlexingMuscles.fbx';
import mutantSwiping from '../models/MutantSwiping.fbx';

document.addEventListener('DOMContentLoaded', function() {
  
  // Type Effect
  let i = 0;
  let txt = 'Well, well, well, look who\'s stumbled into my domain! It\'s me, Bowser, the king of chaos. You think I\'m like those other quick-cash coins? Think again! I\'m here to crush the competition, steal the princess, and make sure nobody forgets who\'s boss around here. This isn\'t some fly-by-night operation, oh no! Built by an experienced crew, we\'re here to stay, stomping on memes with fire and fury.'; /* The text */
  let speed = 50; /* The speed/duration of the effect in milliseconds */

  function typeWriter() {
    if (i < txt.length) {
      document.getElementById("demo").innerHTML += txt.charAt(i);
      i++;
      setTimeout(typeWriter, speed);
    }
  }

  function createAndAnimateStatic() {
    const animationContainer = document.createElement('div');
    animationContainer.style = `
      flex: none;
      height: 100vh;
      left: 0;
      opacity: .12;
      pointer-events: none;
      position: fixed;
      top: calc(50.00000000000002% - 100vh / 2);
      width: 100%;
      z-index: 99999999;
    `;
    const animationInnerContainer = document.createElement('div');
    animationInnerContainer.style = 'width:100%;height:100%;position:relative;overflow:hidden';
    const staticElement = document.createElement('div');
    staticElement.style = `background: url(${static}); opacity: 0.5; inset: -200%; width: 400%; height: 400%; position: absolute;`;

    function animateTransform() {
      const randomTransformX = Math.random() * 14 - 7;
      const randomTransformY = Math.random() * 14 - 7;
      staticElement.style.transform = `translateX(${randomTransformX}%) translateY(${randomTransformY}%) translateZ(0px)`;
    }

    setInterval(animateTransform, 100);
    animationInnerContainer.appendChild(staticElement);
    animationContainer.appendChild(animationInnerContainer);
    document.body.appendChild(animationContainer);
  }

  createAndAnimateStatic();

  const bowserLaugh = new Audio(bowserLaughSound);
  const bowserMusic = new Audio(bowserSong);

  function fadeIn(element, duration) {
    element.style.display = 'block';
    element.style.transition = `opacity ${duration}ms ease-in-out`;
    element.style.opacity = '1';
  }

  function fadeOut(element, duration) {
    element.style.transition = `opacity ${duration}ms ease-in-out`;
    element.style.opacity = '0';
    setTimeout(() => {
      element.style.display = 'none';
    }, duration);
  }

  function scaleBowserLogo() {
    const bowserLogo = document.getElementById('bowserLogo');
    bowserLogo.style.opacity = '1';
    bowserLogo.style.width = '100vw';
    bowserLogo.style.height = 'auto';
  }

  function startThreeJSAnimations() {
    let mixer;
    let currentAction;
    let animations = [];
    let object;
    let currentAnimationIndex = 0;
    const transitionDuration = 1.0;
    let allAnimationsLoaded = false;
    let clock = new THREE.Clock();

    document.body.insertAdjacentHTML('beforeend', '<div id="loading-screen">Loading...</div>');

    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap; // Softer shadows
    document.body.appendChild(renderer.domElement);

    const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
    scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
    directionalLight.position.set(2, 4, 2); // Adjust position above and to the side of the model
    directionalLight.castShadow = true;
    directionalLight.shadow.mapSize.width = 2048;
    directionalLight.shadow.mapSize.height = 2048;
    directionalLight.shadow.camera.near = 0.5;
    directionalLight.shadow.camera.far = 500;
    directionalLight.shadow.bias = -0.0001;
    scene.add(directionalLight);

    const loader = new THREE.FBXLoader();

    loader.load(mutantRoaring, function (loadedObject) {
      object = loadedObject;
      object.scale.set(0.036, 0.036, 0.036); // Increased by 20%
      object.position.y = 0.06; // Slightly increased to compensate for larger size
      object.visible = false;
      object.castShadow = true;
      scene.add(object);

      mixer = new THREE.AnimationMixer(object);

      const animationFiles = [
        mutantRoaring,
        mutantIdle,
        mutantBreathingIdle,
        mutantIdleTwo,
        mutantFlexingMuscles,
        mutantSwiping,
      ];

      let loadedAnimations = 0;

      animationFiles.forEach((file, index) => {
        loader.load(file, (animationObject) => {
          const action = mixer.clipAction(animationObject.animations[0]);
          action.setLoop(THREE.LoopOnce);
          action.clampWhenFinished = true;
          animations[index] = { action, name: file };
          loadedAnimations++;

          if (loadedAnimations === animationFiles.length) {
            allAnimationsLoaded = true;
            object.visible = true;
            document.getElementById('loading-screen').remove();
            playNextAnimation();
          }
        });
      });

      const shadowPlaneGeometry = new THREE.PlaneGeometry(3, 3); // Smaller plane size to focus shadow under the model
      const shadowPlaneMaterial = new THREE.ShadowMaterial({ opacity: 0.4 }); // Adjust opacity for a stronger shadow
      const shadowPlane = new THREE.Mesh(shadowPlaneGeometry, shadowPlaneMaterial);
      shadowPlane.rotation.x = -Math.PI / 2;
      shadowPlane.position.y = 0; // Align with the feet of the model
      shadowPlane.receiveShadow = true;
      scene.add(shadowPlane);
    });

    // camera.position.set(0, 0.5, 1.25); // Adjusted for larger character
    if (window.innerWidth <= 1024) {
      // console.log('mobile');
      camera.position.set(0, 0.5, 1.75); 
    } else {
      // console.log('desktop');
      camera.position.set(0, 0.5, 1.25); 
    }

    function animate() {
      requestAnimationFrame(animate);
      const delta = clock.getDelta();
      if (mixer) mixer.update(delta);
      renderer.render(scene, camera);
    }

    animate();

    function playNextAnimation() {
      if (!animations.length) return;

      const { action: nextAction, name } = animations[currentAnimationIndex];
      // console.log(`Playing animation: ${name}`);

      nextAction.reset();
      nextAction.clampWhenFinished = true;

      if (currentAction) {
        currentAction.crossFadeTo(nextAction, transitionDuration, true);
      } else {
        nextAction.fadeIn(transitionDuration).play();
      }

      nextAction.play();
      currentAction = nextAction;

      mixer.addEventListener('finished', handleAnimationFinish);
    }

    function handleAnimationFinish() {
      mixer.removeEventListener('finished', handleAnimationFinish);
      currentAnimationIndex = (currentAnimationIndex + 1) % animations.length;
      playNextAnimation();
    }

    window.addEventListener('resize', () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      renderer.setSize(width, height);
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
      if (window.innerWidth <= 1024) {
        // console.log('mobile');
        camera.position.set(0, 0.5, 1.75); 
      } else {
        // console.log('desktop');
        camera.position.set(0, 0.5, 1.25); 
      }
    });
  }

  document.getElementById('begin-btn').addEventListener('click', () => {
    fadeOut(document.getElementById('overlay'), 500);

    setTimeout(() => {
      const secondOverlay = document.getElementById('second-overlay');
      secondOverlay.style.display = 'flex';
      fadeIn(secondOverlay, 500);

      setTimeout(() => {
        setTimeout(() => {
          bowserLaugh.play();
        }, 750);
        scaleBowserLogo();

        bowserLaugh.onended = () => {
          fadeOut(secondOverlay, 500);

          bowserMusic.loop = true;
          bowserMusic.play();
          typeWriter();

          startThreeJSAnimations();
        };
      }, 500);
    }, 0);
  });

  document.getElementById('sound-control').addEventListener('click', () => {
    bowserMusic.muted = !bowserMusic.muted;
    document.getElementById('sound-control').textContent = bowserMusic.muted ? 'Unmute' : 'Mute';
  });

  const copyContainer = document.getElementById('contract-address-container');
  const copyStatus = document.getElementById('copy-status');

  copyContainer.addEventListener('click', function() {
    const contractAddress = copyContainer.getAttribute('data-address');

    if (contractAddress) {
      navigator.clipboard.writeText(contractAddress).then(function() {
        copyStatus.style.display = 'inline';

        setTimeout(function() {
          copyStatus.style.display = 'none';
        }, 2000);
      }).catch(function(err) {
        console.error('Could not copy text: ', err);
      });
    } else {
      console.error('No contract address found in data attribute.');
    }
  });
});